/* THIS FILE IS GENERATED ON RUNTIME. DO NOT EDIT */

import { ComponentClassNameFactory } from 'lib/contexts/ComponentClassNameFactory';
import { getPropStylesFactory } from '@/utils/getPropStylesFactory';
import { getComponentStylesFactory } from '@/utils/getComponentStylesFactory';
import { getComponentThemeFactory } from '@/utils/getComponentThemeFactory';
import make_styler from '../index';

export const styles = {
  "front_page": {
    "content_variant_vertical": "flex flex-col group-[&[data-title-position]]/box:grid group-[&[data-title-position]]/box:items-center group-[&[data-title-position=titleLeftSide]]/box:[&>figure]:order-2",
    "content_size_vertical": "group-[&[data-title-position]]/box:[&>figure]:mb-0 group-[&[data-title-position=titleRightSide]]/box:grid-cols-[auto_60%] group-[&[data-title-position=titleLeftSide]]/box:grid-cols-[60%_auto]",
    "media_video_size_default": "w-full",
    "media_image_size_default": "w-full",
    "content_title_size_vertical": "text-headline-sm container-col-lg-8:text-headline-lg sm:text-headline-lg sm:container-col-lg-8:text-headline-xl",
    "variant_vertical": "container-inline",
    "size_vertical": "w-full not-prose",
    "content_text_size_vertical": "px-1.5 py-2 container-col-sm-8:px-3 container-col-sm-8:py-4 sm:px-2 sm:py-4 sm:container-col-md-8:p-6 gap-y-2",
    "content_text_variant_vertical": "flex flex-col",
    "content_title_colors_primary": "",
    "media_playIcon_size_vertical": "w-8 sm:w-13",
    "media_playIcon_variant_vertical": "absolute left-3 top-3 z-1 before:animate-ping before:absolute before:block before:bg-red-600 before:-z-1 before:rounded-full before:sm:w-14 before:sm:h-14 before:w-9 before:h-9 after:absolute after:block after:left-1/2 after:top-1/2 after:-translate-y-1/2 after:-translate-x-1/2 after:sm:w-14 after:sm:h-14 after:w-9 after:h-9 after:bg-white after:z-[-1] after:rounded-full after:border-1 after:border-red-600",
    "headline_size_default": "text-headline-sm container-col-lg-8:text-headline-lg sm:text-headline-lg sm:container-col-lg-8:text-headline-xl",
    "colors_default": "text-black bg-white data-[index=\"1\"]:bg-primary-100"
  },
  "video_pages": {
    "content_variant_vertical": "flex flex-col",
    "content_size_vertical": "gap-3",
    "content_size_horizontal": "",
    "media_video_size_horizontal": "w-full max-w-[310px] max-h-[180px] aspect-video rounded-md",
    "media_image_size_vertical": "w-full max-w-[160px] max-h-[285px] md:max-w-[310px] md:max-h-[540px] aspect-vertical",
    "media_image_variant_vertical": "object-cover",
    "media_playIcon_variant_vertical": "absolute left-1/2 top-auto transform bottom-3 transform -translate-x-1/2 hidden",
    "content_title_size_vertical": "text-headline-sm line-clamp-3",
    "variant_vertical": "container-inline",
    "size_vertical": "w-full not-prose",
    "media_size_horizontal": "basis-[140px] md:basis-[300px] h-fit",
    "extend": {
      "media_playIcon_variant_vertical": "hidden",
      "media_playIcon_variant_horizontal": "hidden",
      "media_video_variant_vertical": "rounded-md",
      "media_video_size_vertical": "aspect-video"
    }
  },
  "tag": {
    "variant_default": "",
    "colors_text": "text-primary-700",
    "size_text": "text-headline-xs sm:container-col-lg-7:text-preamble-md"
  },
  "headline_variant_default": "overflow-hidden",
  "headline_variant_horizontal": "",
  "headline_variant_vertical": "",
  "headline_size_default": "text-headline-xs mb-1.5",
  "headline_size_horizontal": "text-headline-xs mb-2",
  "headline_size_vertical": "",
  "headline_colors_default": "group-[.bg-secondary-900]/box:text-white group-[.bg-primary-700]/box:text-white group-[.bg-black]/box:text-white",
  "headline_colors_primary": "",
  "headline_colors_secondary": "",
  "headline_line_variant_default": "",
  "headline_line_variant_vertical": "",
  "headline_line_variant_horizontal": "",
  "headline_line_colors_default": "after:bg-black",
  "headline_line_colors_primary": "",
  "headline_line_colors_secondary": "",
  "headline_line_size_default": "",
  "headline_line_size_vertical": "after:ml-1.5 after:flex-1 after:h-px",
  "headline_line_size_horizontal": "after:ml-1.5 after:flex-1 after:h-px",
  "content_size_default": "",
  "content_size_vertical": "p-3 border-l-4",
  "content_size_horizontal": "p-3 border-l-4",
  "content_colors_default": "",
  "content_colors_primary": "bg-gray-100",
  "content_colors_secondary": "border-primary-900 bg-gray-100",
  "content_variant_default": "",
  "content_variant_horizontal": "flex grow-0 shrink-0 gap-3",
  "content_variant_vertical": "flex flex-col md:flex-row gap-3",
  "content_title_variant_default": "font-primary font-semibold",
  "content_title_variant_horizontal": "",
  "content_title_variant_vertical": "",
  "content_title_size_default": "",
  "content_title_size_horizontal": "text-headline-sm sm:text-headline",
  "content_title_size_vertical": "text-headline-sm",
  "content_title_colors_default": "",
  "content_title_colors_secondary": "text-white",
  "content_title_colors_primary": "text-black",
  "content_text_variant_default": "flex flex-col items-start",
  "content_text_variant_horizontal": "",
  "content_text_variant_vertical": "",
  "content_text_size_default": "gap-3",
  "content_text_size_horizontal": "",
  "content_text_size_vertical": "",
  "content_text_colors_default": "",
  "content_text_colors_primary": "",
  "content_text_colors_secondary": "",
  "content_hint_variant_default": "font-bold",
  "content_hint_variant_horizontal": "",
  "content_hint_variant_vertical": "",
  "content_hint_colors_default": "",
  "content_hint_colors_primary": "text-primary-300",
  "content_hint_colors_secondary": "text-primary-800",
  "content_hint_size_vertical": "",
  "content_hint_size_horizontal": "",
  "content_hint_size_default": "text-headline-xs md:text-headline-sm",
  "media_variant_default": "",
  "media_variant_vertical": "grow-0 shrink-0 relative",
  "media_variant_horizontal": "relative flex overflow-hidden grow-0 shrink-0",
  "media_size_default": "",
  "media_size_vertical": "rounded-lg h-fit",
  "media_size_horizontal": "basis-[156px] md:basis-[200px] h-fit",
  "media_colors_default": "",
  "media_colors_primary": "",
  "media_colors_secondary": "",
  "media_video_variant_default": "",
  "media_video_variant_horizontal": "",
  "media_video_variant_vertical": "",
  "media_video_size_default": "w-full",
  "media_video_size_horizontal": "w-full",
  "media_video_size_vertical": "w-full",
  "media_video_colors_default": "",
  "media_video_colors_primary": "",
  "media_video_colors_secondary": "",
  "media_image_variant_default": "",
  "media_image_variant_horizontal": "",
  "media_image_variant_vertical": "",
  "media_image_size_default": "w-full",
  "media_image_size_horizontal": "w-full",
  "media_image_size_vertical": "w-full",
  "media_image_colors_default": "",
  "media_image_colors_primary": "",
  "media_image_colors_secondary": "",
  "media_playIcon_size_default": "w-9",
  "media_playIcon_size_vertical": "w-9",
  "media_playIcon_size_horizontal": "w-8",
  "media_playIcon_variant_default": "",
  "media_playIcon_variant_horizontal": "absolute left-2 bottom-2 z-1 before:animate-ping before:absolute before:block before:bg-red-600 before:-z-1 before:rounded-full before:w-9 before:h-9",
  "media_playIcon_variant_vertical": "absolute left-3 top-3",
  "media_playIcon_colors_default": "",
  "media_playIcon_colors_primary": "",
  "media_playIcon_colors_secondary": "",
  "media_icon_colors_default": "",
  "media_icon_colors_primary": "text-red-600 [&>path:last-of-type]:fill-white",
  "media_icon_colors_secondary": "text-primary-300 [&>symbol>path]:fill-white",
  "media_icon_size_default": "",
  "media_icon_size_vertical": "",
  "media_icon_size_horizontal": "",
  "media_icon_variant_default": "",
  "media_icon_variant_horizontal": "",
  "media_icon_variant_vertical": "",
  "variant_horizontal": "container-inline",
  "colors_default": "group-[.bg-secondary-900]/box:text-white group-[.bg-primary-700]/box:text-white group-[.bg-black]/box:text-white",
  "colors_primary": "",
  "colors_secondary": "",
  "size_horizontal": "w-full not-prose",
  "size_default": "w-full"
};

const c = make_styler(styles);

export const propClassName = getPropStylesFactory(c, styles, "JwVideoTeaser");

export const componentClassName = getComponentStylesFactory(propClassName);

export const componentTheme = getComponentThemeFactory(styles);

export const {
  ClassNameContext,
  ClassNameProvider,
  useClassName,
  useTheme,
} = ComponentClassNameFactory(componentClassName, styles);
  
export default c;